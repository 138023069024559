.nameabbr {
  height: 4vmin;
  margin-right: 10px;
  border-radius: 50px;
  }

.jumbotron {
  background-color: transparent;
  /*height: auto;
  background: url("https://wallpapercave.com/wp/wp3076554.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;*/
  /*position: relative;*/
}

.profile_page {
  background-color:rgba(128,128,128,0.3)
}

.avatar {
  height: 250px;
  width: 250px;
  /*margin-right: 10px;*/
  /*border-radius: 50px;*/
  border-radius:50%;
  border-radius:150px;
  display:block;
  filter:grayscale(0%);
  margin:auto;
  padding:1em;
  transition:all 0.6s ease
  }

.avatar:hover {
  background-color: #fff;
  transition:all 0.6s ease
  }

a {
  color: #343a40;
}

a:hover {
  color: #173798;
  text-decoration: none;
}

div.media {
  margin-top: 30px;
  margin-bottom: 10px;
}

div.media .media-heading .jobTotalDuration {
  color: #666;
  font-size: 14px;
  display: block;
}

div.media img {
  height: 10vmin;
  margin-right: 10px;
  border-radius: 50px;
}

div.media .jobDuration {
  color: #444;
  display: block;
}

div.media .jobLocation {
  color: #666;
}

.formLabel {
  font-weight: bold;
}

.certificateLink {
  color: cornflowerblue;
}

footer {
  margin-top: 20px;
  margin-bottom: 10px;
  border-top: #444444;
  text-align: center;
}

footer a {
  color: black;
  font-size: 30px;
  margin-right: 10px;
  margin-left: 10px;
}

.profile_page span {
  background-color: rgba(0,0,0,0.7);
  padding: 1em 0;
  position: relative;
}

.profile_page a:hover {
  text-decoration: none;
}

.profile container {
  color: black;
}

.profile_page h1 {
  font-size: 2em;
  font-weight: 50;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: black;
}

.profile_page h3 {
  font-size: 1em;
  font-weight: 50;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: black;
}

.profile_page h4 {
  font-size: 0.75em;
  font-weight: 50;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: black;
}

.profile_page p {
  line-height: 1.3em;
  font-weight: 300;
  margin-bottom: 0.5em;
}
